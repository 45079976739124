/* eslint-disable no-sequences */
/* eslint-disable no-useless-escape */
import { Address, Language, Option, Picture, Step, Question } from "types";
import { getRequest } from "~/utils/api";

export const isClient = typeof window !== "undefined";
export const isServer = typeof window === "undefined";

export const isAnalyzing = process.env.ANALYZE === "true";
export const isDev = process.env.NODE_ENV === "development";
export const isProd = process.env.NODE_ENV === "production";

export const getKeyByValue = (object: any, value: any) => Object.keys(object).find((key) => object[key] === value);

export const isNumber = (value: any) => !isNaN(parseFloat(value)) && isFinite(value);

export const isTouchDevice = () =>
  isClient &&
  ("ontouchstart" in window || ((window as any).DocumentTouch && document instanceof (window as any).DocumentTouch));

export const isAddress = (variableToCheck: any): variableToCheck is Address =>
  (variableToCheck as Address)?.address !== undefined;

export const isLanguage = (variableToCheck: any): variableToCheck is Language =>
  (variableToCheck as Language)?.level !== undefined;

export const isPicture = (variableToCheck: any): variableToCheck is Picture =>
  (variableToCheck as Picture)?.picture !== undefined;

export const isOption = (variableToCheck: any): variableToCheck is Option =>
  (variableToCheck as Option)?.key !== undefined;

export const isOptionEmpty = (option: any) => {
  if (typeof option === "boolean") return !option;
  if (isPicture(option)) return !option.picture;
  if (isAddress(option)) return option.address === "";
  if (isOption(option)) return option.key === "";
  if (typeof option === "string") return option === "";
  if (Array.isArray(option)) return option.length === 0;
};

export const isQuestionRequired = (requiredQuestions?: string[], question?: Question) => {
  if (!requiredQuestions || !question) return;
  return requiredQuestions.includes(question?.variable_name || "");
};

export const isTermsAndConditions = (activeStep: Step, key: string) => {
  return activeStep?.questions?.[key]?.variable_name === "terms_and_conditions";
};

export const blobToFile = (inputBlob: Blob, fileName: string): File => {
  return new File([inputBlob], fileName, { lastModified: new Date().getTime(), type: inputBlob.type });
};

export const chunk = (arr: any[], size: number): any[][] =>
  // eslint-disable-next-line no-sequences
  arr.reduce((acc, e, i) => (i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc), []);

export const toKebabCase = (str?: string) => {
  if (!str) return;
  return str
    ?.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.map((x) => x.toLowerCase())
    ?.join("-");
};

export const arrayToSentence = (arr: string[]) => {
  const last = arr[arr.length - 1];
  return arr.length > 1 ? arr.slice(0, -1).join(", ") + " e " + last : last;
};

export const orderShifts = (shifts: string[]) => {
  const orderedShifts: string[] = [];
  shifts.map((a) => {
    switch (a.toLowerCase().trim()) {
      case "colazione":
        return (orderedShifts[0] = a);
      case "pranzo":
        return (orderedShifts[1] = a);
      case "aperitivo":
        return (orderedShifts[2] = a);
      case "cena":
        return (orderedShifts[3] = a);
      case "orario pub":
        return (orderedShifts[4] = a);
      default:
        return (orderedShifts[0] = a);
    }
  });
  return orderedShifts.flat();
};

export const groupBy = (arr: any[], key: string) =>
  arr.reduce((acc: any, item: any) => ((acc[item[key]] = [...(acc[item[key]] || []), item]), acc), {});

export const removeProps = (object: Object, keys: string[]) => {
  return Object.entries(object).reduce(
    (a, [key, value]) => (keys.indexOf(key) === -1 ? { ...a, [key]: value } : a),
    {}
  );
};

export const getParameterByName = (name: string, url: string | undefined) => {
  name = name?.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url || "");
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const calculateDuration = (startDate: Date, endDate: Date) => {
  const diff = Math.abs(endDate.getTime() - startDate.getTime());
  const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
  const years = Math.floor(diffDays / 365);
  const months = Math.floor((diffDays % 365) / 30);
  const days = Math.floor(diffDays % 30);
  const yearString = !!years
    ? years > 1
      ? `${years} anni${!!months ? "," : ""} `
      : `${years} anno${!!months ? "," : ""} `
    : "";
  const monthString = !!months ? (months > 1 ? `${months} mesi ` : `${months} mese `) : "";
  const daysString = !years && months < 1 ? `${days} giorni` : "";
  return yearString + monthString + daysString;
};

export const encode = (url: string) =>
  encodeURIComponent(url)
    .replace(/!/g, "%21")
    .replace(/~/g, "%7E")
    .replace(/\*/g, "%2A")
    .replace(/'/g, "%27")
    .replace(/\(/g, "%28")
    .replace(/\)/g, "%29")
    .replace(/%20/g, "+");

export const parsePayloadOption = (option: any, variableName?: string) => {
  // if Option is an array of Languages, parse it like the following
  if (
    Array.isArray(option) &&
    (option.some((o: any) => isLanguage(o)) || variableName === "languages" || variableName === "language")
  ) {
    const parsed = (option as Language[]).map((o) => {
      return {
        language: o.id,
        level: o.level?.id,
      };
    });
    return parsed;
  }
  // if Option is an array
  if (Array.isArray(option)) return (option as Option[])?.map((prop) => prop.id);
  // if Option is an Option and most likely a radio
  // this will be true or false based on "yes" or "no"
  if (
    isOption(option) &&
    variableName !== "displacement_availability" &&
    (option.key === "yes" || option.key === "no")
  ) {
    return option.key === "yes";
  }
  // if Option is an Option, pass its id
  if (isOption(option)) return option.id;

  if (typeof option === "number" && isNaN(option)) {
    return "";
  }
  // if Option is a string, a number, a boolean or an Address, pass it as it is
  if (typeof option === "string" || typeof option === "number" || isAddress(option) || typeof option === "boolean")
    return option;

  return option;
};

export const scrollIntoViewOffset = (element?: Element | null, offset = 0) => {
  if (!element) return;

  const rect = element.getBoundingClientRect();
  const position = rect.top + window.scrollY - offset;
  window.scrollTo({ top: position, behavior: "smooth" });
};

// trigger counter when contacts card is opened
export const triggerContactsCardCounter = async (worker_id: string) => {
  try {
    const res = await getRequest(`/workers/native_cv/open_contacts?worker_id=${worker_id}`);
    if (res.status === 200) return;
  } catch (e) {
    console.error("contact card counter error: ", e);
  }
};

export const aggregateMetricsCounter = (arr: any[]) => {
  let aggregate = 0;
  arr.map((item) => (aggregate += item?.count));
  return aggregate;
};

export const getRestaurantWorkerDistance = async (jwt: string, worker_id: string, restaurant_id: string) => {
  try {
    const res = await getRequest(
      `/workers/compute_restaurant_distance?restaurant_id=${restaurant_id}&worker_id=${worker_id}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );

    if (res.status === 200) {
      return res.data.distance;
    }
  } catch (e) {
    console.error("distance error: ", e);
  }
};
