import axios, { AxiosRequestConfig } from "axios";
import { DocumentNode } from "graphql";
import { request } from "graphql-request";
import { print } from "graphql/language/printer";

export const getRequest = async (endpoint: string, config?: AxiosRequestConfig) => {
  const requestEndpoint = `${process.env.NEXT_PUBLIC_API_URL}${endpoint}`;
  try {
    const res = await axios.get(requestEndpoint, config);
    return res;
  } catch (e) {
    throw e;
  }
};

export const postRequest = (endpoint: string, data: any, config?: AxiosRequestConfig) => {
  const requestEndpoint = `${process.env.NEXT_PUBLIC_API_URL}${endpoint}`;
  return axios.post(requestEndpoint, data, config);
};

export const putRequest = async (endpoint: string, data: any, config?: AxiosRequestConfig) => {
  const requestEndpoint = `${process.env.NEXT_PUBLIC_API_URL}${endpoint}`;
  try {
    const res = await axios.put(requestEndpoint, data, config);
    return res;
  } catch (e) {
    throw e;
  }
};

export const patchRequest = async (endpoint: string, data: any, config?: AxiosRequestConfig) => {
  const requestEndpoint = `${process.env.NEXT_PUBLIC_API_URL}${endpoint}`;
  try {
    const res = await axios.patch(requestEndpoint, data, config);
    return res;
  } catch (e) {
    throw e;
  }
};

export const deleteRequest = async (endpoint: string, config?: AxiosRequestConfig) => {
  const requestEndpoint = `${process.env.NEXT_PUBLIC_API_URL}${endpoint}`;
  try {
    const res = await axios.delete(requestEndpoint, config);
    return res;
  } catch (e) {
    throw e;
  }
};

export const fetchCmsData = async <R = Record<string, unknown>>(
  query: DocumentNode | string,
  vars?: Record<string, unknown>
): Promise<R> => {
  const queryString = typeof query === "string" ? query : print(query);

  const endpoint = process.env.NEXT_PUBLIC_CMS_URL!;

  try {
    const data = await request<R>(endpoint, queryString, vars);
    return data;
  } catch (e) {
    console.error(e);
    return {} as R;
  }
};
